import React from 'react';
import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';
import { Grid, Box, Typography, Link } from '@material-ui/core';
import ImageContainer from '../../../../components/image-container';
import SectionContainerLayoutWithFilter from '../../../../components/section-container-layout-with-filter';

export default function PlanYouTrip() {
  return (
    <Layout>
      <SEO lang='en' title='Plan Your Trip' />
      <SectionContainerLayoutWithFilter isViewAll title='PLAN YOUR TRIP' baseLink='/press/plan-your-trip' isDivider>
        <Grid container>
          <Box position='relative' clone>
            <Grid item xs={12}>
              <ImageContainer filename='booking-2' altText='plan-your-trip' />
              <Box
                position='absolute'
                left='0'
                style={{
                  top: '50%',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(0 , 0 , 0 , 0.5)',
                }}>
                <Box p={2}>
                  <Typography variant='h3' style={{ fontWeight: 'bold' }}>
                    Hotel Direct Booking
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>

          <Grid item xs={12}>
            <Box p={2} height='100%'>
              <Typography variant='h6'>
                <br />
                <b>For hotel direct booking:</b>
                <br />
                <br /> Hilton Dubai Al Habtoor City 5* 
                <br />
                Website:{' '}
                <Link href='https://www.alhabtoorcity.com/hotels/en-us/hilton/home' target='_blank'>
                  https://
                  <wbr />
                  www.alhabtoorcity.com/
                  <wbr />
                  hotels/
                  <wbr />
                  en-us/
                  <wbr />
                  hilton/
                  <wbr />
                  home
                </Link>
                <br /> Rates: 713 AED single / 783 AED double occupancy
                <br />
                To book:{' '}
                <Link href='https://www.my-event.hilton.com/gmis2021/' target='_blank'>
                  www.my-event.hilton.com/
                  <wbr />
                  gmis2021/
                </Link>
                <br />
                Distance to venue: 30 minutes
                <br /> <br />
                Taj Hotel Jumeirah Lakes Towers 5* <br />
                Website:{' '}
                <Link href='https://www.tajhotels.com/en-in/taj/taj-jumeirah-lakes-towers/' target='_blank'>
                  https://
                  <wbr />
                  www.tajhotels.com/
                  <wbr />
                  en-in/
                  <wbr />
                  taj/
                  <wbr />
                  taj-jumeirah-lakes-towers/{' '}
                </Link>
                <br />
                Rates: 655 AED single / 698 AED double occupancy
                <br /> To book, contact:{' '}
                <Link href='mailto: rhea.ghalay@tajhotels.com' target='_blank'>
                  rhea.ghalay@tajhotels.com
                </Link>
                <br /> Distance to venue: 20 minutes (5 minute walk to metro station)
                <br /> <br />
                Premier Inn Hotel Ibn Battuta Mall 3* <br />
                Website:{' '}
                <Link href='https://mena.premierinn.com/en/hotel/dubai-ibn-battuta-mall' target='_blank'>
                  https://mena.premierinn.com/en/hotel/dubai-ibn-battuta-mall
                </Link>
                <br />
                Rates: 360 AED single / double occupancy <br />
                To book, contact:{' '}
                <Link href='mailto: Farhad.pakdaman@mena.premierinn.com' target='_blank'>
                  Farhad.pakdaman@mena.premierinn.com
                </Link>
                <wbr />
                <br />
                Distance to venue: 20 minutes (5 minute walk to the Ibn Batuta Mall metro station)
                <br /> <br />
                Courtyard by Marriott, Dubai Green Community 4* <br />
                Website:{' '}
                <Link
                  href='https://www.marriott.com/hotels/travel/dxbcy-courtyard-dubai-green-community/'
                  target='_blank'>
                  https://www.marriott.com/hotels/travel/dxbcy-courtyard-dubai-green-community/
                </Link>
                <br />
                Rates: 1,056 AED single / 1,117 AED double <br />
                To book, contact:{' '}
                <Link href='mailto: cy.dxbcy.fo@courtyard.com' target='_blank'>
                  cy.dxbcy.fo@courtyard.com
                </Link>
                <br />
                Distance to venue: 10-12 minutes by drive <br />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
}
